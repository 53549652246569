/*---------------------------------------------------------------------------*/
// Import styles
import '../styles/application'

// Import scripts
import '../scripts/csrf_form_injector'

/*---------------------------------------------------------------------------*/
// Import images
const importAll = (r) => r.keys().map(r)
importAll(require.context('../images', true, /\.(png|jpe?g|svg)$/))

/*---------------------------------------------------------------------------*/
// https://firebase.google.com/docs/web/setup
import 'jquery';
import $ from 'jquery';

import M from 'materialize-css';

window.centralAuth = null;

function setJwtHeader(content) {
  let jwtMeta = $('head meta[name="jwt-token"]');
  if (jwtMeta.length >= 1) {
    $(jwtMeta).attr('content', content);
  } else {
    $('head').append(`<meta name="jwt-token" content="${content}">`);
  }
}

// Refresh jwt token on the page header.
function refreshJwtToken() {
  if (window.centralAuth != null && window.centralAuth.user != null) {
    window.centralAuth.user.getIdToken().then(setJwtHeader);
    return;
  } else {
    setJwtHeader('');
  }
}

function displayUserButton(photoUrl, displayName) {
  let linkContent = '';
  if (photoUrl != null) {
    linkContent = `<img src="${photoUrl}" class="avatar" alt="${displayName}" /> ${displayName}`;
  } else {
    linkContent = `<i class="material-icons left">face</i> ${displayName}`;
  }
  
  $("#user-login").attr("data-target", "user-dropdown-logout");
  let loginItem = document.querySelectorAll('#user-login');
  M.Dropdown.init(loginItem, { constrainWidth: false });
  $("#user-login").html(linkContent);
  $("#user-login").show();
}

function displayLoginButton() {
  $("#user-login").attr("data-target", "user-dropdown-login");
  $("#user-login").text("Přihlaste se");
  let loginItem = document.querySelectorAll('#user-login');
  M.Dropdown.init(loginItem, { constrainWidth: false });
  $("#user-login").show();
}

// Refreshes current user's information on the web page.
function refreshUserInfo() {
  // console.log('refreshUserInfo');
  // console.log(window.centralAuth);
  
  // Central auth is initialized
  if (window.centralAuth != null) {
    // User is logged.
    if (window.centralAuth.user != null) {
      displayUserButton(window.centralAuth.user.photoURL, window.centralAuth.user.displayName);
    }
    // User is not logged.
    else {
      displayLoginButton();
    }
  }
  // Central auth is  not initialized.
  else {
    $("#user-login").hide();
  }
}

function setLocalStorageUser() {
  let data = window.localStorage.getItem('centralAuth');
  if (!data) {
    displayLoginButton();
    return;
  }
  
  // console.log('nastavuji token id ze storage');
  let storageUser = JSON.parse(data);
  if (!storageUser.tokenId) {
    return;
  }
  
  setJwtHeader(storageUser.tokenId);
  displayUserButton(storageUser.photoURL, storageUser.displayName);
}

document.addEventListener('DOMContentLoaded', function() {
  // Initialize materialize css.
  M.AutoInit();

  setLocalStorageUser();
  
  $("#user-login-facebook").on("click", function() {
    window.localStorage.setItem('centralAuth', JSON.stringify({}));
    var authProvider = new firebase.auth.FacebookAuthProvider;
    firebase.auth().signInWithRedirect(authProvider);
  });

  $("#user-login-google").on("click", function() {
    window.localStorage.setItem('centralAuth', JSON.stringify({}));
    var authProvider = new firebase.auth.GoogleAuthProvider;
    firebase.auth().signInWithRedirect(authProvider);
  });

  $("#user-logout").on("click", function() {
    firebase.auth().signOut().then(function() {
        window.centralAuth = null;
        location.reload();
      }, function(error) {
        window.centralAuth = null;
        location.reload();
    });
  });

  $("#profile-user-logout").on("click", function() {
    firebase.auth().signOut().then(function() {
        window.centralAuth = null;
        window.location.replace('/');
      }, function(error) {
        window.centralAuth = null;
        window.location.replace('/');
    });
  });

  // Your web app's Firebase configuration
  var firebaseConfig = {
    apiKey: "AIzaSyCx1Cw5ca2lXlIKZWo5Fm3yzNxF0NiJxs4",
    authDomain: "strakonice-mapy.firebaseapp.com",
    databaseURL: "https://strakonice-mapy.firebaseio.com",
    projectId: "strakonice-mapy",
    storageBucket: "strakonice-mapy.appspot.com",
    messagingSenderId: "166598800403",
    appId: "1:166598800403:web:d47fd3e6e6e11bd5"
  };
  
  // Initialize Firebase
  firebase.initializeApp(firebaseConfig);

  firebase.auth().onAuthStateChanged(function(user) {
    if (user) {
      // console.log(user);
      // User is signed in.
      var displayName = user.displayName;
      var email = user.email;
      var emailVerified = user.emailVerified;
      var photoURL = user.photoURL;
      var isAnonymous = user.isAnonymous;
      var uid = user.uid;
      var providerData = user.providerData;
      // console.log(displayName);
      window.centralAuth = {
        user: user
      };
      // console.log('onAuthStateChanged yes user');
      // console.log(user);

      // Retrieve tokenId and save user's information and token id into the local storage.
      user.getIdToken().then(function(tokenId) {
        let storageUser = {
          photoURL: user.photoURL,
          displayName: user.displayName,
          tokenId: tokenId
        };
        
        window.localStorage.setItem('centralAuth', JSON.stringify(storageUser));
        
        const csrf_token = document.querySelector('head meta[name="csrf-token"]').content;
        $.post("/jwt", {
          authenticity_token: csrf_token,
          token: tokenId }, function(result) {
        });
      });
    } else {
      // console.log('onAuthStateChanged no user');
      window.centralAuth = {
        user: null
      };

      window.localStorage.removeItem('centralAuth');
      const csrf_token = document.querySelector('head meta[name="csrf-token"]').content;
        $.post("/jwt", {
          authenticity_token: csrf_token,
          token: null }, function(result) {
        });
    }
    
    refreshUserInfo();
    refreshJwtToken();
  });

  firebase.auth().getRedirectResult().then(function(result) {
    // console.log('redirect ok');
    if (result.credential) {
      // This gives you a Google Access Token. You can use it to access the Google API.
      var token = result.credential.accessToken;
    }
    // The signed-in user info.
    var user = result.user;
  }).catch(function(error) {
    // console.log('redirect error');
    // console.log(error);
    // Handle Errors here.
    var errorCode = error.code;
    var errorMessage = error.message;
    // The email of the user's account used.
    var email = error.email;
    // The firebase.auth.AuthCredential type that was used.
    var credential = error.credential;
    // ...
  });
});

